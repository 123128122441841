<template>
  <div>
    <el-upload
      class="upload-demo"
      ref="upload"
      :action="this.$store.state.uploadFile"
      :headers="this.$tools.config()"
      :data="{
        path_name: 'danger_icon',
      }"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
      list-type="picture"
    >
      <div>
        <el-tag
          v-for="(item, index) in attachment_List"
          :key="index"
          closable
          @close="on_deleteFile(index)"
          >附件</el-tag
        >
      </div>

      <el-button slot="trigger" size="small" type="primary">选择文件</el-button>
    </el-upload>
  </div>
</template>

<script>
export default {
  model: {
    prop: "attachment_List",
    event: "changeData",
  },
  props: ["attachment_List"],
  data() {
    return {};
  },
  methods: {
    handleAvatarSuccess(res) {
      this.attachment_List.push(res.data.url);
      this.$tools.close_open();
    },
    //上传限制
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 20;
      console.log(file);
      const isJPG =
        file.type === "zip" ||
        file.type === "rar" ||
        file.type === "docx" ||
        file.type === "xls" ||
        file.type === "xlsx" ||
        file.type === "pdf" ||
        file.type === "text/plain" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "doc" ||
        file.type === "application/vnd.ms-excel" ||
        file.type === "application/msword" ||
        file.type === "application/pdf" ||
        file.type === "text/xml";

        
      if (!isJPG) {
        this.$message.error("上传文件只能是 pdf ，doc ，xlsx ，docx格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传文件大小不能超过 20MB!");
      }
      console.log(isJPG && isLt2M);
      if (isJPG && isLt2M) {
        this.$tools.shade_open();
      }
      return isJPG && isLt2M;
    },
    on_deleteFile(index) {
      this.attachment_List.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.upload {
  display: flex;
  flex-wrap: wrap;
}
/deep/ .el-upload--picture-card {
  line-height: 80px;
  width: 80px;
  height: 80px;
}
.upload-sty {
  width: 80px;
  height: 80px;
  margin: 0 5px 5px 0;
  position: relative;
  img {
    width: 100%;
    height: 100%;
  }
  i {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    color: red;
    font-weight: bold;
    z-index: 999;
  }
}
</style>